import funcVars from '../../vuex/vuexVars';
import { ActionTree } from 'vuex';
import { AppState, RootState } from '../../vuex/types';
export const actions: ActionTree<AppState, RootState> = {
  async setSimulateOwnerEmail({ commit }, ownerEmail: string) {
    commit(funcVars.updateSimulateOwnerEmail, ownerEmail);

    return ownerEmail;
  },
};
