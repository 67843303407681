import Axios, { AxiosResponse } from 'axios';
import { AccessToken } from '@/services/AuthService';
import NotificationService from '@/services/NotificationService';
import store from '@/vuex/store';
import authService from '@/services/AuthService';
import i18n from '@/i18n/i18n';

export default new (class AxiosMiddleWare {
  public init() {
    // Add JWT to requests
    Axios.interceptors.request.use(
      (config) => {
        if (config?.url?.indexOf('hideLoader') == -1) {
          store.dispatch(
            'setLoaderMessage',
            i18n.global.t('ui.labels.loading')
          );
        }

        // If logged in
        if (AccessToken) {
          config.headers = {
            Authorization: `Bearer ${AccessToken}`,
            'Accept-Language': i18n.global.locale,
          };
        } else {
          config.headers = {
            'Accept-Language': i18n.global.locale,
          };
        }

        // Add current owner if selected
        const simulateOwnerEmail = store.getters['simulateOwnerEmail'];

        if (simulateOwnerEmail) {
          config.headers['Simulate-Owner-Email'] = simulateOwnerEmail;
        }

        const activeLang = i18n.global.locale;

        if (config.url) {
          config.url =
            config.url +
            (config.url.indexOf('?') !== -1 ? '&' : '?') +
            'language=' +
            activeLang;
        }

        return config;
      },
      (error) => {
        store.dispatch('setLoaderMessage', null);
        return Promise.reject(error);
      }
    );

    const extractNotifications = (response: typeof AxiosResponse) => {
      if (
        response &&
        response.data &&
        response.data.notifications &&
        Array.isArray(response.data.notifications)
      ) {
        extractNotificationsList(response.data.notifications);
      }
    };

    const extractNotificationsList = (notificationList: any) => {
      if (notificationList && store.getters['notifications'].length === 0) {
        notificationList.map((notification: any) => {
          NotificationService.create(notification);
        });
      }
    };

    Axios.interceptors.response.use(
      (response) => {
        // Custom response object middleware
        if (response && response.data) {
          // Extract notifications
          extractNotifications(response);
          // If body is wrapped, unwrap it and pass as response
          if (response.data.body) {
            response.data = response.data.body;
          }
        }

        store.dispatch('setLoaderMessage', null);

        return response;
      },
      (error) => {
        // Auth handling middleware
        const originalRequest = error.config;

        if (
          error.response &&
          error.response.status >= 500 &&
          error.response.status < 600
        ) {
          extractNotifications(error.response);
        } else if (error.response && error.response.status === 401) {
          if (!originalRequest._retry) {
            originalRequest._retry = true;

            // Sign in user
            authService.signIn(false);
          }
        }
        // Network error
        else if (!error.status) {
          extractNotificationsList([
            {
              message: i18n.global.t('notifications.error_network'),
              type: 'error',
            },
          ]);
        }

        store.dispatch('setLoaderMessage', null);

        return Promise.reject(error);
      }
    );
  }
})();
