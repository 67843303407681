import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-3"
}

import {
  IonTabBar,
  IonApp,
  IonRouterOutlet,
  IonMenu,
  IonItem,
  IonIcon,
  IonList,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  menuController,
  IonTabButton,
  IonTabs,
  IonFabButton,
  IonSelect,
  IonSelectOption
} from '@ionic/vue';
import NotificationListBlock from './components/common/NotificationListBlock.vue';
import utils from '@/services/Utils';
import LoaderComponent from '@/components/common/LoaderComponent.vue';
import i18n from '@/i18n/i18n';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import store from '@/vuex/store';
import {
  calendarNumberOutline,
  settingsOutline,
  informationOutline,
  readerOutline,
} from 'ionicons/icons';
import ownersResource from './services/OwnersResource';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();

const owners = ref<string[]>([]);

const currentUser = computed(() => {
  return store.getters["currentUser"];
});

if (utils.isAdmin(
  currentUser.value?.emails[0]
)) {
  ownersResource.getOwners().then(response => {
    owners.value = response;
  });
}

window.menuController = menuController;

const selectedSimulateOwnerEmail = ref(store.getters["simulateOwnerEmail"] ?? '');

watch(() => route.path, () => {

  const currentRoute = route.name?.toString();

  if (currentRoute) {
    const metaDescription = i18n.global.te(
      'routes.' + currentRoute + '.meta_description'
    )
      ? i18n.global.t('routes.' + currentRoute + '.meta_description')
        .toString()
      : '';

    const title = i18n.global.te('routes.' + currentRoute + '.meta_title')
      ? i18n.global.t('routes.' + currentRoute + '.meta_title').toString()
      : '';

    // Set metadata
    const metaDescriptionElement = document.querySelector(
      'meta[name="description"]'
    );
    const titleElement: HTMLElement = document.querySelector('title') as HTMLElement;

    if (metaDescriptionElement && metaDescription.length > 1) {
      metaDescriptionElement?.setAttribute('content', metaDescription);
    }

    if (titleElement && title.length > 1) {
      titleElement.innerText = title;
    } else {
      titleElement.innerText = 'Eremit';
    }
  }
});

onMounted(() => {
  if (store.getters['currentUser']) {
    store.dispatch('getReservations');
  }
});

const setSelectedOwner = (event: any) => {
  store.dispatch('setSimulateOwnerEmail', event?.target.value);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonMenu), {
        side: "end",
        menuId: "mainMenu",
        contentId: "main",
        maxEdgeStart: 0
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonHeader), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonToolbar), { translucent: "" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonTitle), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('ui.labels.menu')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(IonContent), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonList), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
            _ctx.$router.push(
              '/' +
              _ctx.$i18n.locale +
              '/' +
              _ctx.$t('routes.about.route') +
              '/' +
              _ctx.$t('routes.about_rv_owner.route')
            )
            ), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(informationOutline),
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('ui.headings.about')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
            _ctx.$router.push(
              '/' + _ctx.$i18n.locale + '/' + _ctx.$t('routes.terms.route')
            )
            ), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(readerOutline),
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('terms.heading')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (
            _ctx.$router.push(
              '/' + _ctx.$i18n.locale + '/' + _ctx.$t('routes.policy.route')
            )
            ), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(readerOutline),
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('policy.heading')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (
            _ctx.$router.push(
              '/' + _ctx.$i18n.locale + '/settings/myplaces/create-area'
            )
            ), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        slot: "start",
                        src: "/images/icons/eremit-icon-black.svg"
                      }),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('ui.buttons.join_eremit')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        src: "/images/icons/sv.svg",
                        slot: "end",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_unref(utils).changeLanguage('sv')), ["prevent"]))
                      }),
                      _createVNode(_unref(IonIcon), {
                        src: "/images/icons/en.svg",
                        slot: "end",
                        onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_unref(utils).changeLanguage('en')), ["prevent"]))
                      }),
                      _createVNode(_unref(IonIcon), {
                        src: "/images/icons/de.svg",
                        slot: "end",
                        onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_unref(utils).changeLanguage('de')), ["prevent"]))
                      })
                    ]),
                    _: 1
                  }),
                  (_unref(utils).isAdmin(
            currentUser.value?.emails[0]
          ))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Kör som", -1)),
                        _createVNode(_unref(IonSelect), {
                          fill: "solid",
                          modelValue: selectedSimulateOwnerEmail.value,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((selectedSimulateOwnerEmail).value = $event)),
                          onIonChange: setSelectedOwner
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonSelectOption), { value: '' }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Ingen (" + _toDisplayString(currentUser.value?.emails ? currentUser.value.emails[0] : '') + ") ", 1)
                              ]),
                              _: 1
                            }),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(owners.value, (owner) => {
                              return (_openBlock(), _createBlock(_unref(IonSelectOption), { value: owner }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(owner), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 256))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonTabs), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet), { id: "main" }),
          _createVNode(_unref(IonTabBar), { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTabButton), {
                tab: "tab1",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(store).dispatch('getReservations'))),
                href: '/' +
          _ctx.$i18n.locale +
          '/' +
          _ctx.$t('routes.reservations.route')
          
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(calendarNumberOutline) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('ui.headings.reservations')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["href"]),
              _createVNode(_unref(IonTabButton), {
                tab: "tab2",
                href: '/' + _ctx.$i18n.locale + '/' + _ctx.$t('routes.areas.route'),
                class: "tab-button-large"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonFabButton), { mode: "md" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        src: "/images/logo-white.svg",
                        size: "large",
                        class: "white-icon"
                      }),
                      _createVNode(_unref(IonIcon), {
                        src: "/images/icons/favicon.svg",
                        size: "large",
                        class: "color-icon"
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('ui.headings.areas')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["href"]),
              _createVNode(_unref(IonTabButton), {
                tab: "tab3",
                href: '/' +
          _ctx.$i18n.locale +
          '/' +
          _ctx.$t('routes.settings.route')
          
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(settingsOutline) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('ui.headings.settings')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["href"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(LoaderComponent),
      _createVNode(NotificationListBlock),
      _cache[10] || (_cache[10] = _createElementVNode("div", { id: "lightbox" }, null, -1))
    ]),
    _: 1
  }))
}
}

})