/*
  hub.ts
  this file is the main hub of all the vuex components.
  all vuex components and their respecive namespace is connected
  to the store and is integreated to the vuex main state here
*/

import Vuex, { Module, StoreOptions } from 'vuex';
import { AppState, RootState } from './types';

// Common
import { actions as CommonActions } from './../components/common/actions';
import { getters as CommonGetters } from './../components/common/getters';
import { mutations as CommonMutations } from './../components/common/mutations';

// Filter Module
import { actions as FilterActions } from './../components/filter/actions';
import { getters as FilterGetters } from './../components/filter/getters';
import { mutations as FilterMutations } from './../components/filter/mutations';

// Area Module
import { actions as AreaActions } from './../components/area/actions';
import { getters as AreaGetters } from './../components/area/getters';
import { mutations as AreaMutations } from './../components/area/mutations';

// User Module
import { actions as UserActions } from './../components/user/actions';
import { getters as UserGetters } from './../components/user/getters';
import { mutations as UserMutations } from './../components/user/mutations';

// Owner Module
import { actions as OwnerActions } from './../components/owner/actions';
import { getters as OwnerGetters } from './../components/owner/getters';
import { mutations as OwnerMutations } from './../components/owner/mutations';

// Reservations Module
import { actions as ReservationsActions } from './../components/reservations/actions';
import { getters as ReservationsGetters } from './../components/reservations/getters';
import { mutations as ReservationsMutations } from './../components/reservations/mutations';

// Comments Module
import { actions as CommentsActions } from './../components/comments/actions';
import { getters as CommentsGetters } from './../components/comments/getters';
import { mutations as CommentsMutations } from './../components/comments/mutations';
import { StorageTypes } from '@/enums/StorageTypes';
import { StorageKeys } from '@/enums/StorageKeys';
import storageService from '@/services/StorageResource';
import constants from '@/utils/Constants';
import { TimeOfDay } from '@/enums/TimeOfDay';
import { AreaCampingType } from '@/enums/AreaCampingType';

const today = new Date();
today.setHours(constants.CheckinHour);
today.setMinutes(0);
today.setSeconds(0);

const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(constants.CheckoutHour);

const timeOfDayFromLocalStorage = storageService.getItem(
  StorageTypes.LOCAL,
  StorageKeys.TIME_OF_DAY
);

const fromDateFromLocalStorage = storageService.getItem(
  StorageTypes.LOCAL,
  StorageKeys.FROM_DATE
);

const toDateFromLocalStorage = storageService.getItem(
  StorageTypes.LOCAL,
  StorageKeys.TO_DATE
);

const propertyValuesFromLocalStorage = storageService.getItem(
  StorageTypes.LOCAL,
  StorageKeys.PROPERTY_VALUES
);

const campingVehicleTypesFromLocalStorage = storageService.getItem(
  StorageTypes.LOCAL,
  StorageKeys.CAMPING_VEHICLE_TYPES
);

const hasShownOnboardingFromLocalStorage = storageService.getItem(
  StorageTypes.LOCAL,
  StorageKeys.SHOWN_ON_BOARDING_SCREEN
);

const simulateOwnerEmail = storageService.getItem(
  StorageTypes.LOCAL,
  StorageKeys.SIMULATE_OWNER_EMAIL
);

export const state: AppState = {
  // data inits
  filters: [],
  activeFilters: [],
  currentUser: undefined,
  simulateOwnerEmail: simulateOwnerEmail,
  timeOfDay: timeOfDayFromLocalStorage ?? TimeOfDay.Morning,
  toDate: toDateFromLocalStorage ? new Date(toDateFromLocalStorage) : tomorrow,
  fromDate: fromDateFromLocalStorage
    ? new Date(fromDateFromLocalStorage)
    : today,
  areaCampingTypes: [AreaCampingType.Eremit],
  campingVehicleTypes: campingVehicleTypesFromLocalStorage ?? [],
  propertyValues: propertyValuesFromLocalStorage ?? [],
  areas: [],
  area: null,
  activeArea: null,
  reservations: [],
  activeTab: 1,
  loaderMessage: null,
  searchTerm: '',
  comments: [],
  areasForUser: [],
  texts: [],
  consumerIdentifier: '',
  hasShownOnboarding: hasShownOnboardingFromLocalStorage,
  notifications: [],
};

const namespaced = false;

export const module: Module<AppState, RootState> = {
  namespaced,
  state,
  getters: Object.assign(
    {},
    CommonGetters,
    FilterGetters,
    AreaGetters,
    UserGetters,
    OwnerGetters,
    ReservationsGetters,
    CommentsGetters
  ),
  actions: Object.assign(
    {},
    CommonActions,
    FilterActions,
    AreaActions,
    UserActions,
    OwnerActions,
    ReservationsActions,
    CommentsActions
  ),
  mutations: Object.assign(
    {},
    CommonMutations,
    FilterMutations,
    AreaMutations,
    UserMutations,
    OwnerMutations,
    ReservationsMutations,
    CommentsMutations
  ),
};

// Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    module,
  },
};

export default new Vuex.Store<RootState>(store);
