import { MutationTree } from 'vuex';
import { AppState } from '../../vuex/types';
import funcVars from '../../vuex/vuexVars';
import { StorageTypes } from '@/enums/StorageTypes';
import { StorageKeys } from '@/enums/StorageKeys';
import storageService from '@/services/StorageResource';

export const mutations: MutationTree<AppState> = {
  [funcVars.updateSimulateOwnerEmail](state: AppState, ownerEmail: string) {
    storageService.setItem(
      StorageTypes.LOCAL,
      StorageKeys.SIMULATE_OWNER_EMAIL,
      ownerEmail
    );
    state.simulateOwnerEmail = ownerEmail;
  },
};
