/*
  vuexVars.ts
  this file contains shared names across the vuex ecosystem.
  the purpose is to have as few places as possible to maintain
  the names of the functions and their respective calls.
*/

export default {
  getActiveFilters: 'getActiveFilters',
  updateSelectedFilter: 'updateSelectedFilter',
  fetchFilters: 'fetchFilters',
  updateShadowFilters: 'updateShadowFilters',
  clearActiveFilters: 'clearActiveFilters',
  updateTimeOfDay: 'updateTimeOfDay',
  updateFromDate: 'updateFromDate',
  updateToDate: 'updateToDate',
  updateAreas: 'updateAreas',
  updateAreaCampingTypes: 'updateAreaCampingTypes',
  updatePropertyValues: 'updatePropertyValues',
  updateCurrentUser: 'updateCurrentUser',
  updateSimulateOwnerEmail: 'updateSimulateOwnerEmail',
  updateReservations: 'updateReservations',
  updateActiveTab: 'updateActiveTab',
  updateLoaderMessage: 'updateLoaderMessage',
  updateSearchTerm: 'updateSearchTerm',
  updateComments: 'updateComments',
  updateArea: 'updateArea',
  updateActiveArea: 'updateActiveArea',
  updateAreasForUser: 'updateAreasForUser',
  updateTexts: 'updateTexts',
  updateConsumerIdentifier: 'updateConsumerIdentifier',
  updateHasShownOnboarding: 'updateHasShownOnboarding',
};
