import funcVars from './../../vuex/vuexVars';
import { ActionTree } from 'vuex';
import { AppState, RootState } from '../../vuex/types';
import utils from '@/services/Utils';
import { IdToken } from '@/services/AuthService';
export const actions: ActionTree<AppState, RootState> = {
  async setCurrentUser({ commit }) {
    const currentUser = IdToken ? utils.parseJwt(IdToken) : undefined;

    commit(funcVars.updateCurrentUser, currentUser);

    return currentUser;
  },
};
