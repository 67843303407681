import Axios from 'axios';
import BaseResource from './BaseResource';
import Owner from '@/models/Owner';

export default new (class OwnersResource extends BaseResource {
  public urlPath = 'owners';

  constructor() {
    super();
  }

  /**
   * This function returns an promise, that can be resolved as a array with areas.
   */
  public getOwner(externalIdentifier: string): Promise<Owner> {
    return Axios.post(this.getBaseUrl() + this.urlPath, {
      externalIdentifier,
    })
      .then((response) => response.data as Promise<Owner>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * This function returns an promise, that can be resolved as a array with areas.
   */
  public getOwners(): Promise<string[]> {
    return Axios.post(`${this.getBaseUrl()}${this.urlPath}/getowners`)
      .then((response) => response.data as Promise<string[]>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }
})();
